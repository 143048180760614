/* eslint-disable react/jsx-no-useless-fragment */
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { FC, PropsWithChildren } from 'react';

import { useProfile } from '@/hooks/useProfile';

import Button from './Button';
import LoadingPlaceholder from './LoadingPlaceholder';
import Text from './Text';

const AuthOnlyPage: FC<PropsWithChildren<{}>> = ({ children }) => {
  const router = useRouter();
  const { data: profileData, isLoading } = useProfile({
    refetchOnWindowFocus: false,
  });

  const whitelistedPaths = [
    '/dashboard/auth/signin',
    '/dashboard/auth/signup',
    '/dashboard/pricing',
    '/academy',
    '/jobs',
    '/jobs/[id]',
  ];

  const isWhitelistedPath = whitelistedPaths.includes(router.pathname);
  if (isWhitelistedPath || profileData) return <>{children}</>;
  if (isLoading) return <LoadingPlaceholder />;
  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center gap-6">
      <div className="flex flex-1 items-center justify-center">
        <div className="flex max-w-md flex-1 flex-col items-start justify-center gap-3">
          <Text variant="body-small" className="text-error">
            Error
          </Text>
          <Text variant="h4">You need to be logged in to access this page</Text>
          <Link className="w-full" href="/dashboard/auth/signin">
            <Button className="w-full self-stretch" variant="secondary">
              Sign in
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AuthOnlyPage;
