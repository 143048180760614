import axios from 'axios';
import qs from 'qs';
import type { InferType, ObjectSchema } from 'yup';
import { array, boolean, mixed, number, object, string } from 'yup';
import type { ZodSchema } from 'zod';
import { z } from 'zod';

import { phoneRegExp } from '@/components/Form/Fields/PhoneInputField';

import { apiRoutes } from './config';
import {
  AvailabilitySchema,
  CurrencySchema,
  JobStartSchema,
  PaymentPeriodSchema,
  ProfileEducationSchema,
  ProfileExperienceSchema,
  SeniorityLevelSchema,
} from './profile';

export const DjangoDataSchema = <T extends object>(
  itemSchema: ObjectSchema<T>
) =>
  object({
    count: number().required(),
    next: string().required().nullable(),
    previous: string().required().nullable(),
    results: array().of(itemSchema).required(),
  });
export type DjangoData<T> = {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
};

export const DjangoDataZodSchema = <T extends object>(
  itemSchema: ZodSchema<T>
) =>
  z.object({
    count: z.number(),
    next: z.string().nullable(),
    previous: z.string().nullable(),
    results: z.array(itemSchema),
  });

export const SkillSchema = object({
  id: number().required(),
  name: string().required(),
});
export type Skill = InferType<typeof SkillSchema>;

const SkillRecordSchema = object({
  skill: SkillSchema,
  yearsOfExperience: number().nullable().defined(),
});
export type SkillRecord = InferType<typeof SkillRecordSchema>;

const LanguageSchema = object({
  id: number().required(),
  name: string().required(),
  code: string().required(),
});
export type Language = InferType<typeof LanguageSchema>;

const LanguageRecordSchema = object({
  level: number().oneOf([0, 1, 2, 3, 4]).nullable().defined(),
  language: LanguageSchema,
}).required();
export type LanguageRecord = InferType<typeof LanguageRecordSchema>;

const EducationRecordSchema = object({
  createdAt: string().required(),
}).concat(ProfileEducationSchema);
export type EducationRecordType = InferType<typeof EducationRecordSchema>;

const ExperienceRecordSchema = object({
  createdAt: string().required(),
}).concat(ProfileExperienceSchema);
export type ExperienceRecordType = InferType<typeof ExperienceRecordSchema>;

const CountrySchema = object({
  id: number().required(),
  name: string().required(),
  continent: string().nullable().defined(),
});
export type Country = InferType<typeof CountrySchema>;

const CitySchema = object({
  id: number().required(),
  name: string().required(),
  country: CountrySchema,
});
export type City = InferType<typeof CitySchema>;

export const InternalApplicationSchema = object({
  id: number().required(),
  recruiteeId: number().nullable().defined(),
  name: string().required(),
  country: CountrySchema.nullable().defined(),
  city: CitySchema.nullable().defined(),
  commercialExperience: number().nullable().defined(),
  languages: array().of(LanguageRecordSchema).required(),
  skills: array().of(SkillRecordSchema).required(),
  createdAt: string(),
  cvText: string().defined(),
  isActiveCandidate: boolean().nullable().defined(),
  offer: string().nullable().defined(),
});
export type InternalApplication = InferType<typeof InternalApplicationSchema>;

const RequestActivitySchema = object({
  createdAt: string().nullable().defined(),
  userChoice: string().nullable().defined(),
  userChoiceMadeAt: string().nullable().defined(),
});

export type RequestActivity = InferType<typeof RequestActivitySchema>;

const CandidateExtraSchema = object({
  activeCandidateStatusRequests: array()
    .of(RequestActivitySchema)
    .notRequired(),
  videoUrl: string().defined(),
  cvUrl: string().defined(),
  financialExpectationsMin: number().nullable().defined(),
  financialExpectationsMax: number().nullable().defined(),
  totalExpectationsMin: number().nullable().defined(),
  totalExpectationsMax: number().nullable().defined(),
  updatedAt: string().required(),
  wantToUseSkills: array().of(number().required()).required(),
  jobStart: JobStartSchema.notRequired().ensure(),
  availability: AvailabilitySchema.notRequired().ensure(),
  seniorityLevel: SeniorityLevelSchema.notRequired().ensure(),
  relocation: boolean().nullable().defined(),
  paymentPeriod: PaymentPeriodSchema.notRequired().ensure(),
  currency: CurrencySchema.notRequired().ensure(),
  typeOfContract: string().nullable().defined(),
  location: string().nullable().defined(),
  experience: array().of(ExperienceRecordSchema).required(),
  education: array().of(EducationRecordSchema).required(),
  allApplications: array().of(number().required()).required(),
  userLastApplicationMadeAt: string().nullable().defined(),
  userLastActiveCandidateStatusChangeAt: string().nullable().defined(),
  belongsToUser: boolean().required(),
  latestRecruiteeId: number().required(),
  linkedinUrl: string().defined(),
});
export type CandidateExtra = InferType<typeof CandidateExtraSchema>;

export const CandidateDataSchema =
  InternalApplicationSchema.concat(CandidateExtraSchema);
export type CandidateData = InferType<typeof CandidateDataSchema>;

export const WaitingListPersonSchema = object({
  email: string()
    .email('Provide a valid email')
    .required('This field is required'),
  phone: string()
    .matches(phoneRegExp, 'Provide a valid phone number')
    .required('This field is required'),
  linkedinUrl: string()
    .url('Provide a valid url')
    .required('This field is required'),
  terms: boolean()
    .isTrue('This field is required')
    .required('This field is required'),
  waitlistRequest: string(),
});

export type WaitingListPerson = InferType<typeof WaitingListPersonSchema>;
export const getSkills = async () => {
  const { data } = await axios.get(apiRoutes.common.skills._root, {
    params: { limit: 999 },
  });
  return DjangoDataSchema(SkillSchema).validate(data);
};

export const getCities = async (country: number | number[] | null) => {
  mixed()
    .test({
      name: 'arrayOrNumber',
      message: 'Invalid value for getCity parametr',
      test: (value) => {
        if (Array.isArray(value)) {
          return array().of(number()).isValidSync(value);
        }
        return number().isValidSync(value);
      },
    })
    .nullable()
    .defined()
    .validate(country);
  const { data } = await axios.get(apiRoutes.common.cities._root, {
    params: {
      limit: 99999,
      country,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
  return DjangoDataSchema(CitySchema).validate(data);
};

export const getCountries = async (continent?: string[] | null) => {
  array().of(string()).nullable().validate(continent);
  const { data } = await axios.get(apiRoutes.common.countries._root, {
    params: { limit: 500, continent },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
  return DjangoDataSchema(CountrySchema).validate(data);
};

export const getLanguages = async () => {
  const { data } = await axios.get(apiRoutes.common.languages._root, {
    params: { limit: 999 },
  });
  return DjangoDataSchema(LanguageSchema).validate(data);
};

export const postWaitingList = async (
  formData: Omit<WaitingListPerson, 'terms'>
) => {
  return axios.post(apiRoutes.common.waitingList, formData);
};
