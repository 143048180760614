import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import classNames from 'classnames';
import type { CSSProperties, ElementType, FC, PropsWithChildren } from 'react';

const textStyles = cva('text-left', {
  variants: {
    variant: {
      'display-1': 'font-workSans text-display-3 md:text-display-1',
      'display-2': 'font-workSans text-h1 md:text-display-2',
      'display-3': 'font-workSans text-h2 md:text-display-3',
      h1: 'font-inter text-h1',
      h2: 'font-inter text-h2',
      h3: 'font-inter text-h3',
      h4: 'font-inter text-h4',
      h5: 'font-inter text-h5',
      body: 'font-inter text-body',
      'body-small': 'font-inter text-body-small',
      'body-caption': 'font-inter text-caption',
      pricing: 'font-workSans text-pricing font-semibold',
      faq: 'font-inter text-faq',
    },
  },
  defaultVariants: {
    variant: 'body',
  },
});

type Props = {
  as?: React.ElementType;
  className?: string;
  style?: CSSProperties;
  title?: string;
} & VariantProps<typeof textStyles>;

const Text: FC<PropsWithChildren<Props>> = ({
  variant,
  className,
  as = 'p',
  children,
  style,
  title,
  ...props
}) => {
  const Elements: { [key: string]: ElementType } = {
    'display-1': 'h1',
    'display-2': 'h1',
    'display-3': 'h1',
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    body: 'p',
    'body-small': 'p',
    'body-caption': 'p',
  };
  const defaultElement: ElementType = Elements[variant ?? 'body'];

  const Element = as ?? defaultElement;
  return (
    <Element
      title={title}
      className={classNames(textStyles({ variant }), {
        [className!]: !!className,
      })}
      style={{ ...style }}
      {...props}
    >
      {children}
    </Element>
  );
};

export default Text;
