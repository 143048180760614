import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import classNames from 'classnames';
import type { FocusEvent, ForwardedRef } from 'react';
import { forwardRef } from 'react';
import type { FieldError } from 'react-hook-form';
import type { CountryData } from 'react-phone-input-2';
import PhoneInput from 'react-phone-input-2';

import ErrorAccordion from '@/components/ErrorAccordion';

import type { ErrorDetailsProps } from '../ArrayDataNestedForm';
import Label from './Label';

const phoneInputStyle = cva(' border-l-0 !py-4 !pr-4 !transition-colors', {
  variants: {
    variant: {
      default:
        'rounded-r-lg border-2 border-transparent bg-secondary-500/20 group-focus-within:!border-secondary-500',
      transparentNeutralBorder:
        '!rounded-r-lg border border-neutral-500 bg-transparent',
      transparent:
        'rounded-r-lg border border-primary-600 bg-neutral-100 !text-black group-focus-within:border-neutral-300 group-hover:border-primary-900 group-active:bg-neutral-100',
      transparentRoundedFullBlack:
        'rounded-r-full border border-neutral-1000 bg-neutral-100 !text-black group-focus-within:border-neutral-300 group-active:bg-neutral-100',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

type PhoneInputStyleProps = VariantProps<typeof phoneInputStyle>;

const dropdownStyle = cva('!rounded-lg !p-4', {
  variants: {
    variant: {
      default: '!bg-secondary-900 !text-white',
      transparentNeutralBorder: '!bg-transparent !text-white',
      transparent: 'scroll-transparent !bg-neutral-100 !text-black',
      transparentRoundedFullBlack:
        'scroll-transparent !bg-neutral-100 !text-black',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

type DropdownStyleProps = VariantProps<typeof dropdownStyle>;

const buttonStyle = cva(
  '!rounded-none border-2 border-r-0 !pl-4 !transition-colors',
  {
    variants: {
      variant: {
        default:
          '!rounded-l-lg border-transparent bg-secondary-500/20 !text-white focus-within:!bg-secondary-500/30 group-focus-within:!border-secondary-500',
        transparentNeutralBorder:
          '!rounded-l-lg !border !border-r-0 border-neutral-500 bg-transparent !text-white',
        transparent:
          '!rounded-l-lg !border !border-r-0 border-primary-600 bg-neutral-100 !text-black group-focus-within:border-neutral-300 group-hover:border-primary-900 group-active:bg-neutral-100',
        transparentRoundedFullBlack:
          '!rounded-l-full !border !border-r-0 border-neutral-1000 bg-neutral-100 !text-black group-focus-within:border-neutral-300 group-active:bg-neutral-100',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

type ButtonStyleProps = VariantProps<typeof buttonStyle>;

interface PhoneInputFieldVariantProps
  extends PhoneInputStyleProps,
    DropdownStyleProps,
    ButtonStyleProps {}
type Props = {
  value?: string;
  onChange: (value: string) => void;
  country?: string;
  label?: string;
  required?: boolean;
  onBlur?: (
    event: FocusEvent<HTMLInputElement, Element>,
    data: {} | CountryData
  ) => void;
  name?: string;
  labelClassName?: string;
} & ErrorDetailsProps<FieldError> &
  PhoneInputFieldVariantProps;
const PhoneInputField = (
  {
    country,
    label,
    required = false,
    error,
    canHaveErrorMessage = true,
    name,
    labelClassName,
    variant,
    ...props
  }: Props,
  ref: ForwardedRef<HTMLInputElement>
) => (
  <div className="group flex flex-col gap-1">
    <Label className={labelClassName} required={required}>
      {label}
    </Label>
    <PhoneInput
      country={country ?? 'us'}
      inputProps={{ ref: ref ?? undefined, name }}
      inputClass={classNames(phoneInputStyle({ variant }), {
        '!border-error !bg-neutral-100 !text-error': error?.message,
      })}
      dropdownClass={dropdownStyle({ variant })}
      buttonClass={classNames(buttonStyle({ variant }), {
        '!border-error !bg-neutral-100 !text-error': error?.message,
      })}
      {...props}
    />
    {canHaveErrorMessage && <ErrorAccordion error={error?.message} />}
  </div>
);

export default forwardRef(PhoneInputField);

export const phoneRegExp = /^\+[1-9]\d{8,14}$/;
